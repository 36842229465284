@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "@smastrom/react-rating/style.css";
@import "react-phone-input-2/lib/style.css";
@import "swiper/css";
@import "swiper/css/pagination";
@import "swiper/css/navigation";

/* Rest of your CSS */

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: black;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 5% !important;
}

.swiper-pagination-bullet {
  width: 16px !important;
  border-radius: 10px !important;
  height: 6px !important;
  background: #ffffff !important;
  opacity: 0.7 !important;
}

.swiper-pagination-bullet-active {
  width: 48px !important;
  border-radius: 100px !important;
  opacity: 1 !important;
  background: white !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(185, 180, 180, 0.6); /* Overlay effect */
  z-index: 9999; /* Ensure it is on top of other content */
}

.circular-progress {
  width: 150px;
  height: 150px;
  border: 4px solid transparent;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.call-container {
  display: flex;
}

.video-container {
  flex: 2;
  height: 400px;
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 400px;
  border: 1px solid #ccc;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.message {
  margin-bottom: 10px;
}

.message .sender {
  font-weight: bold;
}

.message.sent {
  text-align: right;
}

.message.received {
  text-align: left;
}

.input-area {
  display: flex;
  padding: 10px;
}

.input-area input {
  flex: 1;
  margin-right: 10px;
}
